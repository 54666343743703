import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import SettingsApiService from "@/core/services/api.service.settings";
import GeneralSettingsService from "@/core/services/general-settings.service";
import ConfirmDialog from "@/view/pages/ConfirmDialog";

const PARAMETERS = [
  {
    label: "Application Features",
    id: "APPLICATION_FEATURES"
  },
  {
    label: "Main Feed Settings",
    id: "MAIN_FEED_SETTINGS"
  }
];

const APPLICATION_FEATURES = [
  {
    label: "Ai Categorization",
    id: "AI_CATEGORIZATION"
  }
];

const MAIN_FEED_SETTINGS = [
  {
    label: "Cashback Voucher Amount",
    id: "CASHBACK_VOUCHER_AMOUNT"
  },
  {
    label: "Promoted Shops",
    id: "PROMOTED_SHOPS"
  },
  {
    label: "Min price for new selection",
    id: "NEW_SELECTION_MIN_PRICE"
  },
  {
    label: "Ooblee Shop Id",
    id: "OOBLEE_SHOP_ID"
  },
  {
    label: "Special service shops",
    id: "SPECIAL_SERVICE_SHOPS"
  },
  {
    label: "Promoted category",
    id: "PROMOTED_CATEGORY"
  }
];

export default {
  props: {},
  name: "general-settings",
  components: {
    ConfirmDialog
  },
  computed: {
    headers() {
      return [
        { text: "ID", align: "center", value: "id", width: "5%", sortable: false },
        {
          text: "Country",
          value: "country",
          width: "3%",
          align: "center",
          sortable: false
        },
        {
          text: "Cities",
          value: "cities",
          width: "5%",
          align: "center",
          sortable: false
        },
        {
          text: "Name of the property",
          value: "propertyNameLabel",
          align: "center",
          width: "10%"
        },
        {
          text: "Value of property",
          value: "propertyValue",
          align: "center",
          width: "10%"
        },
        {
          actions: "Actions",
          value: "actions",
          aling: "center",
          width: "2%",
          sortable: false
        }
      ];
    }
  },
  data() {
    return {
      title: "General settings",
      valid: false,
      loading: false,
      isEdit: false,
      countries: [],
      selectedCountry: null,
      cities: [],
      parameters: PARAMETERS,
      selectedParameter: null,
      showSidebar: false,
      activeTableData: [],
      archiveTableData: [],
      formNameOfProperties: {
        APPLICATION_FEATURES: APPLICATION_FEATURES,
        MAIN_FEED_SETTINGS: MAIN_FEED_SETTINGS
      },
      settingGroupId: null,
      form: this.createForm(),
      requiredRule: [v => !!v || "Field is required"],
      showPromoFields: false
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
    await SchedulerApiService.init();
    this.getCountries();
  },
  methods: {
    createForm() {
      return {
        selectedCountry: null,
        selectedCity: null,
        parameters: null,
        selectedParameter: {
          label: null,
          id: null
        },
        selectedNameProperty: null,
        propertyValue: null,
        valueType: null
      };
    },
    getCountries() {
      SchedulerApiService.getCountries().then(res => {
        this.countries = res.data || [];
      });
    },
    async getCities(countryId) {
      if (!countryId) return;
      const res = await SchedulerApiService.getCities({ countryId });
      this.cities = res.data || [];
      return true;
    },
    openSidebar() {
      this.showSidebar = true;
    },
    closeSidebar() {
      this.showSidebar = false;
      this.resetEditData();
    },
    resetEditData() {
      this.isEdit = false;
      this.settingGroupId = null;
      this.form = this.createForm();
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    getSettings() {
      GeneralSettingsService.getGeneralSettingsGroup(
        this.selectedParameter.id,
        this.selectedCountry.id
      ).then(async res => {
        if (!res.data?.length) return;
        await this.getCities(this.selectedCountry.id);
        this.handleSettingsResponse(res.data);
      });
    },

    handleSettingsResponse(data) {
      this.activeTableData = [];
      this.archiveTableData = [];

      data.forEach(item => {
        item["country"] =
          this.countries.find(country => country.id === item.countryId)?.name || null;
        item["cities"] = this.cities.find(city => city.id === item.cityId)?.name || "All cities";
        item["propertyNameLabel"] =
          MAIN_FEED_SETTINGS.find(setting => setting.id === item.propertyName)?.label ||
          APPLICATION_FEATURES.find(setting => setting.id === item.propertyName)?.label;

        const today = new Date();
        const validUntil = new Date(item.validUntil);

        if (item.validUntil === null || validUntil > today) {
          this.activeTableData.push(item);
        } else {
          this.archiveTableData.push(item);
        }
      });
    },
    onSelectCountry() {
      this.getCities(this.form.selectedCountry?.id);
    },

    async save() {
      if (!this.$refs.form.validate()) return;

      const propertyGroup = this.form.selectedParameter.id;
      const propertyName = this.form.selectedNameProperty.id;

      const postModel = {
        countryId: this.form.selectedCountry.id,
        cityId: this.form.selectedCity?.id || null,
        propertyValue: this.form.propertyValue.toString(),
        valueType: this.form?.valueType || null
      };

      try {
        let request = null;
        if (this.isEdit) {
          request = GeneralSettingsService.updateSettingGroup(this.settingGroupId, postModel);
        } else {
          request = GeneralSettingsService.createSettingGroup(
            propertyGroup,
            propertyName,
            postModel
          );
        }

        this.loading = true;
        await request.finally(() => {
          this.loading = false;
        });

        if (!this.selectedCountry && !this.selectedParameter) {
          this.selectedCountry = this.form.selectedCountry;
          this.selectedParameter = this.form.selectedParameter;
        }

        this.getSettings();
        this.closeSidebar();
        this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
          title: this.$i18n.t("General settings"),
          solid: true
        });
      } catch (error) {
        this.$bvToast.toast("Error: " + error, {
          title: this.$i18n.t("menu.messages"),
          solid: true,
          variant: "error",
          autoHideDelay: 2000
        });
      }
    },

    async deleteSetting(settingGroup) {
      const confirm = await this.$refs.confirm.open(
        this.$i18n.t("common.confirm"),
        this.$i18n.t("common.areYouSure") + "?"
      );

      if (!confirm) return;

      //Deny deletion of the setting if some properties are null
      const errorMessage = "You can't delete this! This is default setting.";
      if (
        (settingGroup.countryId && !settingGroup.cityId) ||
        (!settingGroup.countryId && settingGroup.propertyName) ||
        (!settingGroup.countryId &&
          !settingGroup.cityId &&
          settingGroup.propertyName &&
          settingGroup.propertyValue) ||
        (settingGroup.countryId && settingGroup.cityId === "All cities") ||
        (!settingGroup.countryId && settingGroup.cityId === "All cities")
      ) {
        this.$bvToast.toast("Error: " + errorMessage, {
          title: "There is an error!",
          solid: true,
          variant: "error",
          autoHideDelay: 2000
        });
        return;
      }

      SettingsApiService.deleteSetting(settingGroup.propertyGroup, settingGroup.id).then(() => {
        this.activeTableData = this.activeTableData.filter(item => item.id !== settingGroup.id);
        this.archiveTableData = this.archiveTableData.filter(item => item.id == settingGroup.id);
      });
    },

    editSetting(settingGroup) {
      this.isEdit = true;
      this.form = this.setForm(settingGroup);
      this.settingGroupId = settingGroup.id;
      this.openSidebar();
    },

    setForm(settingGroup) {
      return {
        selectedCountry: this.countries.find(country => country.id === settingGroup.countryId),
        selectedCity: this.cities.find(country => country.id === settingGroup.cityId),
        parameters: PARAMETERS,
        selectedParameter: PARAMETERS.find(item => item.id === settingGroup.propertyGroup),
        selectedNameProperty:
          MAIN_FEED_SETTINGS.find(setting => setting.id === settingGroup.propertyName) ||
          APPLICATION_FEATURES.find(setting => setting.id === settingGroup.propertyName),
        propertyValue: settingGroup.propertyValue,
        valueType: settingGroup.valueType
      };
    },

    checkFieldType() {
      if (!this.form.selectedNameProperty?.id) return;

      const FILEDS_TYPE_NUMBER = [
        "CASHBACK_VOUCHER_AMOUNT",
        "GIFT_CARD_VOUCHER_AMOUNT",
        "OOBLEE_SHOP_ID"
      ];

      const isNumber = FILEDS_TYPE_NUMBER.find(item => item === this.form.selectedNameProperty.id);

      return isNumber ? "number" : "text";
    }
  }
};
