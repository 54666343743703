var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.superAdmin)?_c('div',[_c('v-data-table',{staticClass:"elevation-1 class-on-data-table",attrs:{"headers":_vm.headers,"items":_vm.orders,"options":_vm.pagination,"server-items-length":_vm.totalOrders,"loading":_vm.loading,"show-expand":"","footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100],
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.totalSum",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalSum + "€")+" ")]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{staticStyle:{"padding":"0.5em 0.5em 1em 1em"},attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"dense":"","headers":_vm.orderItemHeaders,"items":item.orderItems,"disable-pagination":true,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.finalPrice",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"old-price"},[_vm._v(_vm._s(item.priceBeforeDiscount + "€"))]),_vm._v(" "+_vm._s(item.finalPrice + "€")+" ")]}},{key:"item.discountInPercentage",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.discountInPercentage + "%")+" ")]}},{key:"item.productPicture",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":item.pictureUrls.thumb_path3,"target":"_blank"}},[(item != null && item.pictureUrls != null)?_c('v-img',{attrs:{"src":item.pictureUrls.thumb_path3,"contain":"","max-height":"100","max-width":"100"}}):_vm._e()],1)]}}],null,true)})],1)]}},{key:"item.created",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.created))+" ")]}},{key:"item.shopName",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.shopDetails != null ? item.shopDetails.shopName : "")+" ")])]}},{key:"item.orderInfo",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"color":"teal","size":"26","dark":""},on:{"click":function($event){return _vm.goToOrderInfoDialog(item)}}},[_vm._v("mdi-information-outline ")])]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveOrders();}},model:{value:(_vm.shopName),callback:function ($$v) {_vm.shopName=$$v},expression:"shopName"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveOrders();}},model:{value:(_vm.customerName),callback:function ($$v) {_vm.customerName=$$v},expression:"customerName"}})],1),_c('td',[_c('v-datetime-picker',{attrs:{"label":_vm.$t('date.from'),"time-format":"HH:mm:ss"},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('v-datetime-picker',{attrs:{"label":_vm.$t('date.to'),"time-format":"HH:mm:ss"},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-row',[_c('v-select',{attrs:{"items":_vm.orderStatusList,"dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
                _vm.retrieveOrders();}},model:{value:(_vm.orderStatus),callback:function ($$v) {_vm.orderStatus=$$v},expression:"orderStatus"}}),_c('v-col'),_c('v-col',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                    _vm.retrieveOrders();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('v-col',[_c('v-avatar',{attrs:{"color":"grey","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                    _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1)],1)],1)])]},proxy:true}],null,false,3142181360)}),_c('div',{staticClass:"table-footer-prepend d-flex pl-2 align-center"},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"loading":_vm.downloadingReport,"disabled":_vm.downloadingReport,"color":"green darken-1"},on:{"click":function($event){_vm.loader = 'downloadingReport';
        _vm.downloadReport();}}},[_vm._v(" "+_vm._s(_vm.$t("pages.orders.or"))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-file-excel ")])],1),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"loading":_vm.downloadingItemsReport,"disabled":_vm.downloadingItemsReport,"color":"green darken-1"},on:{"click":function($event){_vm.loader = 'downloadingItemsReport';
        _vm.downloadItemsReport();}}},[_vm._v(" "+_vm._s(_vm.$t("pages.orders.oir"))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-file-excel ")])],1)],1),_c('ProductDialog',{attrs:{"shopProduct":_vm.shopProduct},on:{"close":function($event){_vm.showProductDialog = false}},model:{value:(_vm.showProductDialog),callback:function ($$v) {_vm.showProductDialog=$$v},expression:"showProductDialog"}}),_c('OrderInfoDialog',{attrs:{"orderInfo":_vm.orderInfo},on:{"close":function($event){_vm.showOrderInfoDialog = false}},model:{value:(_vm.showOrderInfoDialog),callback:function ($$v) {_vm.showOrderInfoDialog=$$v},expression:"showOrderInfoDialog"}}),_c('GalleryDialog',{attrs:{"mediaList":_vm.mediaList},on:{"close":function($event){_vm.showGalleryDialog = false}},model:{value:(_vm.showGalleryDialog),callback:function ($$v) {_vm.showGalleryDialog=$$v},expression:"showGalleryDialog"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }